import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { AdxadSidebarModal } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { MessageService } from '../../../../../../core/services/message.service';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import { Observable, Subject } from 'rxjs';
import { SegmentFormComponent } from '../segment-form/segment-form.component';
import {
  DynamicGridAction,
  FilterChanges,
  FiltersConfig,
  GridRequest,
  SegmentObject,
  SegmentsData
} from '../../../../../../core/interface';
import { FiltersService } from '../../../../../../core/services/filters.service';
import { SegmentViewComponent } from '../segment-view/segment-view.component';
import { SegmentsService } from '../../segments.service';
import { SidebarFilterComponent } from '../../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { DspService } from '../../../../../../core/services/dsp.service';
import { Status } from 'src/app/core/configs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-segments-grid',
  templateUrl: './segments-grid.component.html',
  styleUrls: ['./segments-grid.component.scss'],
  providers: [FiltersService]
})
export class SegmentsGridComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isLoadingChangeStatus = false;
  public isNotFound = false;
  public isNotCreated = false;
  public readonly Status = Status;

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<SegmentsData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.segmentsService.getSegments(data))
  );

  constructor(
    private messageService: MessageService,
    private modal: AdxadModal,
    private alerts: AdxadAlerts,
    private sidebarModal: AdxadSidebarModal,
    public filter: FiltersService,
    private segmentsService: SegmentsService,
    private dspService: DspService
  ) {}

  ngOnInit(): void {
    this.paginator.init();
    this.subscribeFilter();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      (result: SegmentsData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      () => (this.isLoading = false)
    );

    this.messageService
      .get('reload-segments-grid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data.submit) {
          this.loadGrid();
        }
      });

    this.loadFilters();
  }

  /**
   * Load & init filters list;
   * Call loadGrid or show error message
   */
  loadFilters(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.dspService
      .getFilters('segments')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ({ data, status }: FiltersConfig) => {
          this.isLoading = false;

          if (status === 'OK') {
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        () => (this.isLoading = false)
      );
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Open create segment form
   */
  openForm(): void {
    this.modal
      .open(SegmentFormComponent, {
        width: '1120px'
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.loadGrid();
        }
      });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set segments grid
   * @param {SegmentsData} result
   */
  private setGrid(result: SegmentsData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * openView - load & open segment detail in sidebar modal
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }

    const segment = action.value as SegmentObject;

    if (action.action === 'toggleStatus') {
      const status = segment.status === this.Status.active ? this.Status.stopped : this.Status.active;
      this.changeStatus([segment], status);
    }

    if (action.action === 'openView') {
      if (!action.value.id) {
        return;
      }
      this.openSidebarModal(action.value.id);
    }
  }

  /**
   * Change status in segments
   *
   * @param {SegmentObject[]} segments
   * @param {number} status
   */
  changeStatus(segments: SegmentObject[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    const data = {
      ids: segments.map(x => x.id),
      status: status
    };

    segments.forEach(x => (x.isLoading = true));
    this.segmentsService
      .changeSegmentsStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        result => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data || !result.data.length) {
              this.alerts.error('Oops, something wrong', 3000);
              return;
            }

            segments.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                this.alerts.error('Segment ' + x.name + ' has not changed status', 3000);
                return true;
              }

              if (x.status !== obj.status) {
                let message = 'Segment ' + x.name;
                switch (obj.status) {
                  case Status.active: {
                    message += ' is activated';
                    break;
                  }
                  case Status.stopped: {
                    message += ' is stopped';
                    break;
                  }
                  default: {
                    message += ' is changed';
                  }
                }

                this.alerts.success(message, 3000);
                x.status = obj.status;
              }
            });

            this.dynamicGrid.resetMultiSelect();
          }
        },
        () => {
          this.isLoadingChangeStatus = false;
          segments.forEach(x => (x.isLoading = false));
        }
      );
  }

  /**
   * Change status in selected segments
   *
   * @param {number} status
   */
  multiChangeStatus(status: number): void {
    const segment = this.dynamicGrid.getChecked();
    this.changeStatus(segment, status);
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return this.isLoadingChangeStatus || !(this.dynamicGrid && this.dynamicGrid.getChecked().length);
  }

  /**
   * @return {boolean} flag to disable actions buttons
   */
  get isDisableActions(): boolean {
    return this.isLoading || this.isLoadingChangeStatus;
  }

  /**
   * Open detail segment view
   *
   * @param id of segment
   */
  openSidebarModal(id: string): void {
    if (!id) {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    this.sidebarModal.open(SegmentViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  ngOnDestroy() {
    this.messageService.remove('reload-segments-grid');
  }
}
