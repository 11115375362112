<adxad-loader
  adxadSidebarModalLoader
  [diameter]="40"
  *ngIf="isLoading || isLoadingStatus"
></adxad-loader>

<adxad-sidebar-modal-container
  class="creative-view"
  *ngIf="ssp && !isLoading"
>
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{ ssp.name }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section adxadSidebarModalSection>
      <app-cut-url [url]="ssp.url"></app-cut-url>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Status"
      >
        <adxad-entity-status [entityStatus]="ssp.status"></adxad-entity-status>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Traffic category"
      >
        {{ ssp.trafficCategory | titlecase }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Segment"
      >
        {{ ssp.segment }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Commission, %"
      >
        {{ ssp.commission }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Minimal CPM, $"
      >
        {{ ssp.cpm }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Force response type"
      >
        {{ ssp.responseType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Type notice about win"
      >
        {{ ssp.noticeType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Adm format"
      >
        {{ ssp.admFormat }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Internal SSP"
        *ngIf="ssp.isInternal"
      >
        Enabled
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Check pops by imp.ext"
        *ngIf="ssp.checkPops"
      >
        Enabled
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Min CPM only for pops"
        *ngIf="ssp.cpmOnlyPops"
      >
        Enabled
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Force response only pops"
        *ngIf="ssp.responseOnlyPops"
      >
        Enabled
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Sensitive"
      >
        {{ ssp.risk }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Api key"
      >
        <adxad-ssp-api-key
          [apiKey]="ssp.apiKey"
          [sspId]="ssp.id"
        />
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      *ngIf="ssp.mediaTypeFilter"
      [hideSeparator]="!ssp.placementFilter"
      adxadSidebarModalSection
      class="row"
    >
      <h4 class="col-12">Media type filter</h4>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Selected media types"
      >
        <adxad-chip-list>
          <adxad-chip *ngFor="let mediaType of ssp.mediaTypes"> {{ mediaType }} </adxad-chip>
        </adxad-chip-list>
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      *ngIf="ssp.placementFilter"
      [hideSeparator]="true"
      adxadSidebarModalSection
      class="row"
    >
      <h4 class="col-12">Blacklists for placements</h4>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Selected placement filter media types"
      >
        <adxad-chip-list>
          <adxad-chip *ngFor="let mediaType of ssp.placementFilterMediaTypes"> {{ mediaType }} </adxad-chip>
        </adxad-chip-list>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Placement ids"
      >
        <adxad-chip-list>
          <adxad-chip *ngFor="let id of ssp.placementFilterIds"> {{ id }} </adxad-chip>
        </adxad-chip-list>
      </adxad-sidebar-modal-info-block>
    </section>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <button
      adxadSidebarModalAction
      actionType="main-gray"
      type="button"
      (click)="openEditForm()"
    >
      <span class="material-icons">edit</span>
      Edit
    </button>

    <button
      *ngIf="ssp.status === Status.stopped"
      adxadSidebarModalAction
      actionType="main-green"
      (click)="changeStatus(Status.active)"
    >
      <span class="material-icons">play_arrow</span>
      Activate
    </button>

    <button
      *ngIf="ssp.status === Status.active"
      adxadSidebarModalAction
      actionType="main-red"
      (click)="changeStatus(Status.stopped)"
    >
      <span class="material-icons">stop</span>
      Stop
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
