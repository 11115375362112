import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { BlackListSpotsGridComponent } from './components/black-list-spots-grid/black-list-spots-grid.component';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { BulkToggleSitesComponent } from './components/bulk-toggle-sites/bulk-toggle-sites.component';

const ENTRY_COMPONENTS = [BlackListSpotsGridComponent];

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule, TranslocoModule];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS, BulkToggleSitesComponent]
})
export class BlackListSpotsModule {}
