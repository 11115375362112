<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ isNewSegment ? 'New ' : 'Edit '}} segment</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <div class="row">
      <adxad-form-field class="field-group col-3">
        <adxad-input
          [autofocus]="true"
          formControlName="name"
          label="Name"
          placeholder="Enter name"
        />
        <app-show-errors [control]="form.get('name')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-3">
        <adxad-input
          formControlName="extId"
          label="External ID"
          placeholder="Enter external id"
        ></adxad-input>
        <app-show-errors [control]="form.get('extId')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-3">
        <adxad-select
          placeholder="Select traffic type"
          label="Traffic type"
          formControlName="trafficType"
          [options]="dicts.trafficTypes.data"
        ></adxad-select>
        <app-show-errors [control]="form.get('trafficType')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-3">
        <adxad-number-input
          label="Sort"
          placeholder="Enter sort"
          formControlName="sort"
          [step]="1"
        >
        </adxad-number-input>
        <app-show-errors [control]="form.get('sort')"></app-show-errors>
      </adxad-form-field>
    </div>

    <div class="adxad-section-title">Select scopes</div>

    <adxad-form-field class="field-group">
      <adxad-holders
        [holders]="dicts.scopes.data"
        [isToggleAll]="true"
        formControlName="scope"
        label="Scopes"
      ></adxad-holders>

      <app-show-errors [control]="form.get('scope')"></app-show-errors>
    </adxad-form-field>

    <div class="adxad-section-title">Select roles</div>

    <adxad-form-field class="field-group">
      <adxad-holders
        [holders]="dicts.roles.data"
        [isToggleAll]="true"
        formControlName="roles"
        label="Roles"
      ></adxad-holders>

      <app-show-errors [control]="form.get('roles')"></app-show-errors>
    </adxad-form-field>

    <div class="adxad-section-title">Composite segment</div>

    <adxad-form-field class="field-group checkbox-group">
      <adxad-checkbox formControlName="isComposite"> Enable composite segment</adxad-checkbox>
    </adxad-form-field>

    <adxad-form-field class="field-group">
      <adxad-holders
        [holders]="dicts.includeSegments.data"
        [isToggleAll]="true"
        formControlName="includeSegments"
        label="Segments"
        *ngIf="form.get('isComposite').value"
      ></adxad-holders>

      <app-show-errors [control]="includeSegments"></app-show-errors>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        CANCEL
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
      >
        {{ (isNewSegment ? 'Create' : 'Save changes') | uppercase }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
