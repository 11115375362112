import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenToolComponent } from './token-tool/token-tool.component';
import { GuiModule } from '../../../../gui/gui.module';

@NgModule({
  declarations: [TokenToolComponent],
  imports: [CommonModule, GuiModule]
})
export class TokenToolModule {}
