<div class="row">
  <div class="col-6">
    <adxad-textarea
      [(ngModel)]="value"
      [height]="height"
      [disabled]="isLoading"
      label="Decode / encode token"
      placeholder="Enter data"
    ></adxad-textarea>
  </div>

  <div class="col-6">
    <adxad-label> Result </adxad-label>
    <div
      [innerHTML]="encryptedValue$ | async"
      class="result"
    ></div>
  </div>
</div>

<div class="actions row">
  <div class="col-6">
    <adxad-submit-button
      (click)="submit('decode')"
      [isLoading]="isLoadingSubmit"
      [disabled]="disabledBtn"
    >
      Decode
    </adxad-submit-button>

    <adxad-submit-button
      (click)="submit('encode')"
      [isLoading]="isLoadingSubmit"
      [disabled]="disabledBtn"
    >
      Encode
    </adxad-submit-button>

    <button
      (click)="clear()"
      [disabled]="disabledBtn"
      adxadStrokedButton
    >
      Clear
    </button>
  </div>
  <div class="col-6">
    <button
      (click)="copy()"
      *ngIf="(encryptedValue$ | async)"
      adxadStrokedButton
      icon="filter_none"
    >
      Copy result
    </button>
  </div>
</div>
