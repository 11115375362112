import { NgModule } from '@angular/core';
import { SegmentsGridComponent } from './components/segments-grid/segments-grid.component';
import { SegmentViewComponent } from './components/segment-view/segment-view.component';
import { SegmentFormComponent } from './components/segment-form/segment-form.component';
import { GuiModule } from '../../../../gui/gui.module';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';

const ENTRY_COMPONENTS = [SegmentsGridComponent, SegmentViewComponent, SegmentFormComponent];

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS]
})
export class SegmentsModule {}
