<adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

<adxad-modal-header>
  <h2 adxadModalTitle>Create new translation key's</h2>
</adxad-modal-header>

<adxad-modal-content [formGroup]="form">
  <ng-container
    formArrayName="keys"
    *ngFor="let item of keys.controls; let i = index"
  >
    <adxad-form-field class="field-group">
      <adxad-input
        [formControlName]="$any(i)"
        label="Translation key"
        placeholder="Enter key"
        [suffix]="true"
      >
        <span
          class="material-icons adxad-suffix remove"
          *ngIf="i !== 0"
          (click)="removeKey(i)"
        >
          delete_outline
        </span>
      </adxad-input>
      <app-show-errors [control]="keys.controls[i]"></app-show-errors>
    </adxad-form-field>
  </ng-container>

  <button
    adxadButton
    (click)="addKey()"
    class="w100"
  >
    Add more key
  </button>
</adxad-modal-content>

<adxad-modal-actions class="row">
  <div class="col">
    <button
      adxadStrokedButton
      class="w100"
      (click)="close()"
    >
      Cancel
    </button>
  </div>
  <div class="col">
    <adxad-submit-button
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
      [isLoading]="isLoadingSubmit"
    >
      Save
    </adxad-submit-button>
  </div>
</adxad-modal-actions>
