<div class="header">
  <div class="adxad-grid-header">
    <button
      (click)="openForm()"
      adxadButton
      class="adxad-grid-header__add-btn red"
      icon="add"
    >
      Create segment
    </button>
  </div>

  <section
    [ngClass]="{'hide': isNotCreated}"
    class="adxad-toolbar"
  >
    <div class="adxad-actions">
      <button
        (click)="multiChangeStatus(Status.stopped)"
        [disabled]="isDisableMultiActions"
        adxadStrokedButton
        class="action-btn"
        icon="pause"
        iconHoverColor="error"
      >
        Stop
      </button>

      <button
        (click)="multiChangeStatus(Status.active)"
        [disabled]="isDisableMultiActions"
        adxadStrokedButton
        class="action-btn"
        icon="play_arrow"
        iconHoverColor="success"
      >
        Activate
      </button>
    </div>
  </section>

  <section class="filter-toolbar">
    <adxad-calendar
      (changeDates)="filter.changeCalendarDates($event)"
      [from]="filter.calendar?.value?.calendar.from"
      [to]="filter.calendar?.value?.calendar.to"
    ></adxad-calendar>

    <adxad-filter-actions
      (applyFilter)="applyFilter()"
      (clearFilters)="clearFilters()"
      (openFilter)="openFilter()"
      [count]="filter.count"
      [notApplied]="filter.isNotApplied"
    ></adxad-filter-actions>
  </section>
</div>

<adxad-dynamic-grid
  (action)="gridAction($event)"
  (reload)="loadGrid()"
  [loading]="isLoading"
  [ngClass]="{ 'hide': isNotFound || isNotCreated }"
/>
<adxad-grid-not-found
  (clearFilters)="clearFilters()"
  *ngIf="isNotFound"
/>
<section
  class="not-created"
  *ngIf="isNotCreated"
>
  <img
    class="not-creates__icon"
    src="/assets/images/icons/not-created-icon.svg"
    alt=""
  />
  <h2 class="not-created__title">No segments</h2>
  <p class="not-created__text">
    There`s no segments yet.
    <br />
    Add segments to work
  </p>
  <button
    adxadButton
    (click)="openForm()"
  >
    Create
  </button>
</section>
<adxad-paginator (changePage)="loadGrid()" />
