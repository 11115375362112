import { Component } from '@angular/core';
import { SETTINGS_ROUTE } from '../../../core/routes';
import { RolesService } from '../../../core/services/roles.service';
import { LayoutService } from '../../../layout/layout.service';

@Component({
  selector: 'adxad-layout',
  templateUrl: './settings-layout.component.html',
  styleUrls: ['./settings-layout.component.scss']
})
export class SettingsLayoutComponent {
  public tabs;

  constructor(
    private roles: RolesService,
    public layoutService: LayoutService
  ) {
    this.tabs = [
      {
        name: 'SSPs',
        link: './' + SETTINGS_ROUTE.ssps,
        showTab: this.roles.isAdmins()
      },
      {
        name: 'Segments',
        link: './' + SETTINGS_ROUTE.segments,
        showTab: this.roles.isSuperAdmin()
      },
      {
        name: 'Events log',
        link: './' + SETTINGS_ROUTE.events,
        showTab: this.roles.isSuperAdmin()
      },
      {
        name: 'Translations',
        link: './' + SETTINGS_ROUTE.translations,
        showTab: this.roles.isManagers()
      },
      {
        name: 'Black list sites',
        link: './' + SETTINGS_ROUTE.spots,
        showTab: this.roles.isSuperAdmin()
      },
      {
        name: 'Token tool',
        link: './' + SETTINGS_ROUTE.tokenTool,
        showTab: this.roles.isManagers()
      }
    ];
  }
}
