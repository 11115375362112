import { Component, DestroyRef, inject } from '@angular/core';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { BlackListSpotsService } from '../../black-list-spots.service';
import { Status } from '../../../../../../core/configs';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-bulk-toggle-sites',
  templateUrl: './bulk-toggle-sites.component.html',
  styleUrls: ['./bulk-toggle-sites.component.scss']
})
export class BulkToggleSitesComponent {
  public value = '';
  public isLoading = false;
  public readonly Status = Status;

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private spotsService: BlackListSpotsService,
    private alerts: AdxadAlerts,
    private translate: TranslocoService
  ) {}

  /**
   * Activate or block sites
   *
   * @param {'activate' | 'block'} action
   */
  submit(action: 'activate' | 'block'): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const ids = [
      ...new Set(
        this.value
          .split('\n')
          .map(x => x.trim())
          .filter(String)
      )
    ];
    const status = action === 'activate' ? Status.active : Status.stopped;

    const data = {
      ids,
      status: status
    };

    this.spotsService
      .changeSpotsStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status, warning }) => {
          this.isLoading = false;

          if (status !== 'OK' || !data) {
            this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
            return;
          }

          if (warning) {
            this.alerts.warning(warning, 3000);
          }

          if (data.length) {
            let message = action === 'activate' ? 'alert_spotsIAreActivated' : 'alert_spotsIAreBlocked';
            this.alerts.success(this.translate.translate(message), 3000);
            this.modalRef.close({ submit: true });
          } else {
            this.close();
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Close modal
   */
  close(): void {
    this.modalRef.close();
  }
}
