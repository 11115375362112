import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { TranslationsService } from '../../translations.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-translation-key-form',
  templateUrl: './translation-key-form.component.html',
  styleUrls: ['./translation-key-form.component.scss']
})
export class TranslationKeyFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoadingSubmit = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: AdxadModalRef,
    private translationsService: TranslationsService,
    private alerts: AdxadAlerts
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      keys: this.fb.array([])
    });

    this.addKey();
  }

  get keys(): UntypedFormArray {
    return this.form.get('keys') as UntypedFormArray;
  }

  addKey() {
    this.keys.push(this.fb.control('', [Validators.required]));
  }

  /**
   * Remove geo
   * @param i - index
   */
  removeKey(i: any) {
    this.keys.removeAt(i);
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;

    this.translationsService
      .addTranslateKey(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (response.status === 'OK') {
            this.isLoadingSubmit = false;

            this.alerts.success('Key successfully create', 3000);
            this.close(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
