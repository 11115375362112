import { Routes } from '@angular/router';
import { SettingsLayoutComponent } from './layout/settings-layout.component';
import { SETTINGS_ROUTE } from '../../core/routes';
import { SspsGridComponent } from './modules/ssps/components/ssps-grid/ssp-grid.component';
import { SegmentsGridComponent } from './modules/segments/components/segments-grid/segments-grid.component';
import { EventsLogGridComponent } from './modules/events-log/events-log-grid/events-log-grid.component';
import { TranslationsGridComponent } from './modules/translations/components/translations-grid/translations-grid.component';
import { BlackListSpotsGridComponent } from './modules/black-list-spots/components/black-list-spots-grid/black-list-spots-grid.component';
import { TokenToolComponent } from './modules/token-tool/token-tool/token-tool.component';

export const routes: Routes = [
  {
    path: '',
    component: SettingsLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: SETTINGS_ROUTE.ssps,
        pathMatch: 'full'
      },
      {
        path: SETTINGS_ROUTE.ssps,
        component: SspsGridComponent,
        title: 'Settings • SSPs'
      },
      {
        path: SETTINGS_ROUTE.segments,
        component: SegmentsGridComponent,
        title: 'Settings • Segments'
      },
      {
        path: SETTINGS_ROUTE.events,
        component: EventsLogGridComponent,
        title: 'Settings • Events log'
      },
      {
        path: SETTINGS_ROUTE.translations,
        component: TranslationsGridComponent,
        title: 'Settings • Translations'
      },
      {
        path: SETTINGS_ROUTE.spots,
        component: BlackListSpotsGridComponent,
        title: 'Settings • Black list spots'
      },
      {
        path: SETTINGS_ROUTE.tokenTool,
        component: TokenToolComponent,
        title: 'Settings • Token tool'
      }
    ]
  }
];
