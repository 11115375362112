import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { Dict, Dicts, LazyLoadDict } from '../../../../../../core/interface';
import { DspService } from '../../../../../../core/services/dsp.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { SspsService } from '../../ssps.service';
import { MessageService } from '../../../../../../core/services/message.service';
import { forkJoin } from 'rxjs';
import { RolesService } from '../../../../../../core/services/roles.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-ssp-form',
  templateUrl: './ssp-form.component.html',
  styleUrls: ['./ssp-form.component.scss']
})
export class SspFormComponent implements OnInit {
  form: UntypedFormGroup;
  isLoading = false;
  isLoadingSubmit = false;
  isNewSSP = true;

  dicts = {
    responseType: {
      data: [
        { id: 'disabled', value: 'Disabled' },
        { id: 'interstitial', value: 'Interstitial' }
      ]
    },
    noticeType: {
      data: [
        { id: 'nurl', value: 'Nurl' },
        { id: 'burl', value: 'Burl' }
      ]
    },
    trafficCategory: {
      data: [
        { id: 'adult', value: 'Adult' },
        { id: 'mainstream', value: 'Mainstream' }
      ]
    },
    admFormat: {
      data: [
        { id: 'main', value: 'Main' },
        { id: 'exads', value: 'Exads' },
        { id: 'mirelia', value: 'MA mirelia' }
      ]
    },
    costModel: {
      data: [
        { id: 'cpc', value: 'CPC' },
        { id: 'cpm', value: 'CPM' }
      ]
    },
    macros: {
      data: []
    },
    mediaTypes: {
      data: []
    },
    sspsSensitiveTypes: {
      data: []
    },
    segments: {
      data: []
    }
  } as Dicts;
  cursorPosition = 0;

  private destroyRef = inject(DestroyRef);

  constructor(
    private alerts: AdxadAlerts,
    private fb: UntypedFormBuilder,
    public config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private dspService: DspService,
    private sspsService: SspsService,
    private messageService: MessageService,
    private rolesService: RolesService
  ) {}

  ngOnInit(): void {
    if (!this.rolesService.isAdmins()) {
      this.close();
      return;
    }

    if (this.config.data && this.config.data.id) {
      this.isNewSSP = false;
    }

    this.createForm();

    if (this.isNewSSP) {
      this.loadData();
    } else {
      this.loadSsp(this.config.data.id);
    }
  }

  /**
   * Return media type control
   */
  get mediaTypes(): UntypedFormControl {
    return this.form.get('mediaTypes') as UntypedFormControl;
  }

  /**
   * Return isNoOpenRTB value
   */
  get isNoOpenRTB(): UntypedFormControl {
    return this.form.get('isNoOpenRTB') as UntypedFormControl;
  }

  /**
   * Create form
   */
  createForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      extId: ['', [Validators.required]],
      trafficCategory: ['adult', [Validators.required]],
      segment: ['partner', [Validators.required]],
      commission: ['20', [Validators.required, Validators.min(0)]],
      cpm: ['0', [Validators.required, Validators.min(0)]],
      responseType: ['disabled', [Validators.required]],
      mediaTypes: [[]],
      cpmOnlyPops: false,
      mediaTypeFilter: false,
      internal: false,
      checkPops: false,
      responseOnlyPops: false,
      noticeType: 'nurl',
      admFormat: 'main',
      onlyValidHtml: false,
      enabledShortUrl: false,
      costModel: 'cpm',
      isNoOpenRTB: false,
      accessToken: '',
      responseTemplate: '',
      risk: 1
    });

    if (!this.isNewSSP) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }

    this.form
      .get('name')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        const val = value.toLowerCase().replace(/\s+/g, '-');
        this.form.get('extId').setValue(val);
      });

    this.form
      .get('mediaTypeFilter')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value) {
          this.mediaTypes.setValidators(Validators.required);
        }

        if (!value) {
          this.mediaTypes.patchValue([]);
          this.mediaTypes.clearValidators();
        }

        this.mediaTypes.updateValueAndValidity();
      });

    this.isNoOpenRTB.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        const accessToken = this.form.get('accessToken');
        const responseTemplate = this.form.get('responseTemplate');

        if (value && !accessToken.value) {
          this.generateSecret();
        } else if (!value) {
          accessToken.setValue('');
          responseTemplate.setValue('');
        }

        accessToken.updateValueAndValidity();
        responseTemplate.updateValueAndValidity();
      }
    });
  }

  /**
   * Load ssp form dicts
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    const forkList = [
      this.dspService.getMediaTypesForEdit(request),
      this.dspService.getSspSegments(request),
      this.dspService.getSspsPlaceholders(request),
      this.dspService.getSspsSensitives(request)
    ];

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([mediaTypes, segments, macros, sspsSensitiveTypes]: LazyLoadDict[]) => {
          this.dicts = {
            ...this.dicts,
            mediaTypes,
            segments,
            macros,
            sspsSensitiveTypes
          };

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Load ssp data
   *
   * @param id - ssp id
   */
  loadSsp(id: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.sspsService
      .getSspForm({ id: id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;
          this.loadData();

          this.form.patchValue(result.data);
          this.form.updateValueAndValidity();
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Set cursor position on blur field
   *
   * @param {FocusEvent} e
   */
  setCursorPosition(e: FocusEvent): void {
    this.cursorPosition = e.target['selectionStart'];
  }

  /**
   * Add macros in responseTemplate
   * or replace all same holders
   *
   * @param {Dict} macros
   */
  toggleMacros(macros: Dict): void {
    const control = this.form.get('responseTemplate');
    let code = control.value;

    if (code.indexOf(macros.id) !== -1) {
      const re = new RegExp(macros.id, 'g');
      code = code.replace(re, '');
    } else {
      code = code.slice(0, this.cursorPosition) + macros.id + code.slice(this.cursorPosition);
    }
    control.setValue(code);
  }

  /**
   * Generate secret token if Enable JSON/XML integration
   */
  generateSecret(): void {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let newSecret = '';

    for (let i = 0; i < 32; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      newSecret += chars[randomIndex];
    }

    this.form.get('accessToken').patchValue(newSecret);
    this.form.get('accessToken').updateValueAndValidity();
  }

  /**
   * Submit ssp form
   */
  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;
    const data = this.form.value;

    const method = this.isNewSSP ? this.sspsService.addSsp(data) : this.sspsService.editSsp(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        this.isLoadingSubmit = false;
        if (response.status === 'OK') {
          const message = 'SSP successfully ' + (this.isNewSSP ? 'created' : 'changed');
          this.messageService.add('reload-ssps-grid', { submit: true });
          this.alerts.success(message, 3000);
          this.close(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
