<adxad-loader
  *ngIf="isLoading"
  [diameter]="40"
  adxadModalLoader
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>Set commission</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-form-field class="field-group">
      <adxad-number-input
        [prefix]="true"
        formControlName="commission"
        label="Commission"
        placeholder="Enter commission"
        step="1"
      >
        <span class="adxad-prefix">%</span>
      </adxad-number-input>

      <app-show-errors [control]="form.get('commission')"></app-show-errors>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        (click)="close()"
        adxadStrokedButton
        class="w100"
      >
        Cancel
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        (click)="submit()"
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        [isLoading]="isLoadingSubmit"
      >
        Save
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
