<div
  [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
  class="adxad-main-container"
>
  <adxad-tabs-nav>
    <ng-container *ngFor="let tab of tabs">
      <adxad-tab-link
        *ngIf="tab.showTab"
        [routerLink]="tab.link"
        routerLinkActive="active"
      >
        {{ tab.name }}
      </adxad-tab-link>
    </ng-container>
  </adxad-tabs-nav>

  <router-outlet></router-outlet>
</div>
