import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { AdxadSidebarModalRef } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadSidebarModalConfig } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { SSP, SSPResponse } from '../../../../../../core/interface';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { SspFormComponent } from '../ssp-form/ssp-form.component';
import { MessageService } from '../../../../../../core/services/message.service';
import { SspsService } from '../../ssps.service';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { Status } from 'src/app/core/configs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-ssp-view',
  templateUrl: './ssp-view.component.html',
  styleUrls: ['./ssp-view.component.scss']
})
export class SspViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isLoadingStatus = false;
  public ssp: SSP;
  public readonly Status = Status;
  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    private modal: AdxadModal,
    public config: AdxadSidebarModalConfig,
    private alerts: AdxadAlerts,
    private messageService: MessageService,
    private queryParamsService: QueryParamsService,
    private SSPsService: SspsService
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data || !this.config.data.id) {
      this.alerts.error('Oops something went wrong', 3000);
      this.close();
    }

    this.queryParamsService.add('sm_sspView', this.config.data.id);

    this.loadData();
  }

  /**
   * Load ssp data
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.SSPsService.getSsp({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (result: SSPResponse) => {
          this.isLoading = false;

          if (result.status === 'OK') {
            this.ssp = result.data as SSP;
          }
        },
        () => {
          this.isLoading = false;
          this.close();
        }
      );
  }

  /**
   * Open edit ssp form in modal
   */
  openEditForm(): void {
    this.modal
      .open(SspFormComponent, {
        width: '1120px',
        data: this.ssp
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.loadData();
        }
      });
  }

  /**
   * Activate or stop ssp
   *
   * @param {number} status
   */
  changeStatus(status: number): void {
    if (status === undefined || this.isLoadingStatus) {
      return;
    }

    this.isLoadingStatus = true;
    const data = {
      ids: [this.config.data.id],
      status: status
    };

    this.SSPsService.changeSspStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        result => {
          this.isLoadingStatus = false;

          if (result.status === 'OK') {
            this.messageService.add('reload-ssps-grid', { submit: true });

            this.ssp.status = result.data[0].status;
            const message = 'The ssp is ' + (this.ssp.status === this.Status.stopped ? 'stopped' : 'activated');
            this.alerts.success(message, 3000);
          }
        },
        () => (this.isLoadingStatus = false)
      );
  }

  /**
   * Close modal
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  ngOnDestroy() {
    this.queryParamsService.remove('sm_sspView');
  }
}
