<div class="header">
  <div class="adxad-grid-header">
    <button
      (click)="openForm()"
      adxadButton
      class="adxad-grid-header__add-btn red"
      icon="add"
    >
      Create ssp
    </button>
  </div>
  <section class="filter-toolbar">
    <button
      (click)="setMassCommission()"
      [disabled]="isDisableMultiActions"
      adxadStrokedButton
      class="action-btn"
      icon="percent"
      iconHoverColor="error"
    >
      Set commission
    </button>

    <div class="filter-toolbar__right-col">
      <adxad-dg-column-selector
        *ngIf="columns.length"
        [columns]="columns"
        gridPrefix="sspsGridHiddenColumns"
      ></adxad-dg-column-selector>

      <adxad-filter-actions
        (applyFilter)="applyFilter()"
        (clearFilters)="clearFilters()"
        (openFilter)="openFilter()"
        [count]="filter.count"
        [disabled]="isLoadingFilters"
        [notApplied]="filter.isNotApplied"
      ></adxad-filter-actions>
    </div>
  </section>
</div>

<adxad-dynamic-grid
  (action)="gridAction($event)"
  (reload)="loadGrid()"
  [loading]="isLoading"
  [ngClass]="{ 'hide': isNotFound || isNotCreated }"
/>

<adxad-grid-not-found
  *ngIf="isNotFound"
  [showClearFilters]="false"
/>

<section
  class="not-created"
  *ngIf="isNotCreated"
>
  <img
    class="not-creates__icon"
    src="/assets/images/icons/not-created-icon.svg"
    alt=""
  />
  <h2 class="not-created__title">No SSPs</h2>
  <p class="not-created__text">There`s no ssp yet.</p>
  <button
    adxadButton
    color="red"
    (click)="openForm()"
  >
    {{ 'Add ssp' | uppercase }}
  </button>
</section>

<adxad-paginator
  (changePage)="loadGrid()"
  [disabled]="isLoading"
/>
