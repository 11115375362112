import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { SspsService } from '../../ssps.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-ssp-api-key',
  templateUrl: './ssp-api-key.component.html',
  styleUrls: ['./ssp-api-key.component.scss']
})
export class SspApiKeyComponent implements OnInit {
  @Input({ required: true })
  apiKey = '';

  @Input({ required: true })
  sspId: string = null;

  key$ = new BehaviorSubject<string>('');

  private destroyRef = inject(DestroyRef);

  constructor(
    private clipboard: Clipboard,
    private sspService: SspsService,
    private alerts: AdxadAlerts
  ) {}

  @HostBinding('class.is-empty')
  get currentApiKey() {
    return this.apiKey === null;
  }

  ngOnInit(): void {
    if (this.apiKey) {
      this.key$.next(this.apiKey);
    }
  }

  /**
   * Generate a new API key
   */
  generateKey(): void {
    this.sspService
      .getSspApiKey(this.sspId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.key$.next(result.data.apiKey);
          this.apiKey = result.data.apiKey;
        },
        error: () => this.key$.next(null)
      });
  }

  /**
   * Copy the value to the clipboard
   */
  copyValue(): void {
    this.clipboard.copy(this.key$.value);
    this.alerts.success('Copied successfully', 1500);
  }
}
