<div class="header">
  <section class="adxad-toolbar">
    <button
      (click)="multiChangeStatus(Status.stopped)"
      [disabled]="isDisableMultiActions"
      adxadStrokedButton
      class="adxad-toolbar__item"
      icon="stop"
      iconHoverColor="error"
    >
      {{ 'action_block' | transloco }}
    </button>

    <button
      (click)="multiChangeStatus(Status.active)"
      [disabled]="isDisableMultiActions"
      adxadStrokedButton
      class="adxad-toolbar__item"
      icon="play_arrow"
      iconHoverColor="success"
    >
      {{ 'action_activate' | transloco }}
    </button>

    <button
      (click)="openBulkSelect()"
      adxadStrokedButton
      class="adxad-toolbar__item"
    >
      {{ 'action_bulkToggleSites' | transloco }}
    </button>

    <adxad-input
      [(ngModel)]="searchValue"
      class="adxad-toolbar__search adxad-toolbar__item"
      placeholder="Search"
    ></adxad-input>

    <adxad-checkbox
      (changeChecked)="toggleBlocked()"
      [(ngModel)]="onlyBlocked"
      [checked]="onlyBlocked"
      [disabled]="isLoading"
      class="adxad-toolbar__item"
    >
      Only blocked
    </adxad-checkbox>

    <adxad-checkbox
      *ngIf="false"
      [(ngModel)]="onlyBlocked"
      class="adxad-toolbar__item"
    >
      Only blocked
    </adxad-checkbox>

    <button
      (click)="clearFilter()"
      adxadStrokedButton
      class="adxad-toolbar__item"
    >
      {{ 'action_clearFilters' | transloco }}
    </button>

    <button
      (click)="applyFilter()"
      adxadButton
      class="adxad-toolbar__item"
    >
      {{ 'action_apply' | transloco }}
    </button>
  </section>
</div>

<adxad-dynamic-grid
  (action)="gridAction($event)"
  (reload)="loadGrid()"
  [loading]="isLoading"
/>
<adxad-paginator (changePage)="loadGrid()" />
