<div class="header">
  <div class="adxad-grid-header">
    <button
      (click)="openForm()"
      adxadButton
      class="adxad-grid-header__add-btn red"
      icon="add"
    >
      Create key
    </button>
  </div>
</div>

<section class="adxad-toolbar">
  <div class="adxad-actions">
    <div class="checkbox-group">
      <adxad-checkbox
        *ngFor="let locale of availableLanguages"
        (changeChecked)="changeSelectedLang(locale)"
        [disabled]="(selectedLocales.length > 4 && !selectedLocales.includes(locale)) || locale === 'en'"
        [checked]="selectedLocales.includes(locale)"
      >
        {{ getLanguageName(locale) }}
      </adxad-checkbox>
    </div>
  </div>

  <adxad-input
    placeholder="Filter by translate key"
    [prefix]="true"
    [suffix]="true"
    [(ngModel)]="translateKeyFilter"
  >
    <span class="material-icons adxad-prefix">search</span>
    <span
      class="material-icons adxad-suffix --clear-input"
      (click)="translateKeyFilter = ''"
    >
      close
    </span>
  </adxad-input>
</section>

<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoadingLocales.length > 0 || isFromPreparing"
></adxad-loader>

<section
  class="locales"
  [formGroup]="form"
  *ngIf="isLoadingLocales.length === 0 && !isFromPreparing"
>
  <div
    class="locale"
    *ngFor="let locale of selectedLocales"
  >
    <ng-container *ngIf="!isLoadingLocales.includes(locale)">
      <div class="locale__title">
        <img
          class="locale__title-icon"
          alt="{{ getLanguageName(locale) }}"
          src="/assets/images/flags/{{ locale }}.svg"
        />

        {{ getLanguageName(locale) }} ({{ locale }})

        <span
          class="material-icons-outlined warning"
          adxadTooltip="Attention, there are empty fields!"
          placement="left"
          *ngIf="getLanguageGroup(locale).invalid"
        >
          error
        </span>
      </div>

      <ng-container *ngIf="getLanguageGroup(locale) as langGroup">
        <ng-container [formGroupName]="locale">
          <ng-container *ngFor="let controlName of getLanguageGroupControls(langGroup) | searchContext: translateKeyFilter; let i = index">
            <!--            <div class="adxad-subtitle" *ngIf="setGroupTitle(i, langGroup)">-->
            <!--              {{ getGroupTitle(controlName.split('_')[0]) }}-->
            <!--            </div>-->

            <adxad-form-field
              class="field-group"
              [class.--empty]="isEmpty(langGroup.controls[controlName])"
            >
              <adxad-label>
                <span class="material-icons error-icons"> error </span>
                {{ controlName | truncate: 24 }}

                <span
                  class="material-icons copy-value"
                  (click)="copyValue(controlName)"
                >
                  filter_none
                </span>
              </adxad-label>

              <adxad-textarea
                [formControl]="$any(langGroup.controls[controlName])"
                [fixWidth]="true"
              ></adxad-textarea>

              <app-show-errors [control]="langGroup.controls[controlName]"></app-show-errors>

              <div class="btn-group">
                <button
                  adxadButton
                  [disabled]="!langGroup.controls[controlName].valid ||
                        !langGroup.controls[controlName].dirty ||
                        isLoadingItems === 'loading-' + locale + i"
                  (click)="save(locale, controlName, langGroup.controls[controlName].value, i, langGroup)"
                >
                  <adxad-loader
                    class="submit-loader"
                    [diameter]="20"
                    *ngIf="isLoadingItems === 'loading-' + locale + i"
                  ></adxad-loader>

                  <span [ngClass]="{'loading': isLoadingItems === 'loading-' + locale + i}"> Save </span>
                </button>

                <button
                  adxadStrokedButton
                  (click)="langGroup.controls[controlName].reset()"
                >
                  Clear
                </button>

                <!--                <button adxadStrokedButton-->
                <!--                        (click)="addLocale(locale, langGroup, controlName, langGroup.controls[controlName].value)"-->
                <!--                        *ngIf="locale !== 'en'"-->
                <!--                >-->
                <!--                  Add locale-->
                <!--                </button>-->
              </div>
            </adxad-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</section>
