<div class="value">
  <ng-container *ngIf="key$ | async as key; else generateApiKey"> {{ key }} </ng-container>

  <ng-template #generateApiKey>
    <div
      (click)="generateKey()"
      class="generate-action"
    >
      Generate api key
    </div>
  </ng-template>
</div>

<div class="actions">
  <button
    (click)="generateKey()"
    [stroked]="true"
    adxadIconButton
    adxadTooltip="Refresh api key"
  >
    <span class="material-icons"> refresh </span>
  </button>

  <button
    (click)="copyValue()"
    [stroked]="true"
    adxadIconButton
    adxadTooltip="Copy api key"
  >
    <span class="material-icons --copy"> filter_none </span>
  </button>
</div>
