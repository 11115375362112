<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{isNewSSP ? 'New SSP' : 'Edit ' + config.data.name}}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <div class="adxad-section-title">General settings</div>

    <div class="row">
      <adxad-form-field class="field-group col-4">
        <adxad-input
          [autofocus]="true"
          formControlName="name"
          label="Name"
          placeholder="Enter SSP name"
        />
        <app-show-errors [control]="form.get('name')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-input
          formControlName="extId"
          label="External ID"
          placeholder="Enter SSP external id"
        />
        <app-show-errors [control]="form.get('extId')" />
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-select
          [options]="dicts.sspsSensitiveTypes.data"
          formControlName="risk"
          label="Sensitive"
          placeholder="Select ssp sensitive"
        ></adxad-select>
      </adxad-form-field>
    </div>
    <div class="row">
      <adxad-form-field class="field-group col-auto">
        <adxad-checkbox formControlName="internal">Internal SSP</adxad-checkbox>
      </adxad-form-field>

      <adxad-form-field class="field-group col-auto">
        <adxad-checkbox formControlName="checkPops">Check pops by imp.ext</adxad-checkbox>
      </adxad-form-field>

      <adxad-form-field class="field-group col-auto">
        <adxad-checkbox formControlName="onlyValidHtml">Accepted only valid html creative</adxad-checkbox>
      </adxad-form-field>

      <adxad-form-field class="field-group col-auto">
        <adxad-checkbox formControlName="enabledShortUrl">Enabled short URL's</adxad-checkbox>
      </adxad-form-field>
    </div>

    <div class="row">
      <adxad-form-field class="field-group col-4">
        <adxad-select
          placeholder="Select traffic category"
          label="Traffic category"
          formControlName="trafficCategory"
          [options]="dicts.trafficCategory.data"
        ></adxad-select>
        <app-show-errors [control]="form.get('trafficCategory')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-select
          placeholder="Select segment"
          label="Segment"
          formControlName="segment"
          [options]="dicts.segments.data"
        ></adxad-select>
        <app-show-errors [control]="form.get('segment')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-number-input
          formControlName="commission"
          label="Commission"
          placeholder="Enter commission"
          [prefix]="true"
          step="1"
        >
          <span class="adxad-prefix">%</span>
        </adxad-number-input>
        <app-show-errors [control]="form.get('commission')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-number-input
          formControlName="cpm"
          label="Minimum CPM"
          placeholder="Enter min CPM"
          [prefix]="true"
        >
          <span class="material-icons adxad-prefix">attach_money</span>
        </adxad-number-input>
        <app-show-errors [control]="form.get('cpm')"></app-show-errors>

        <adxad-checkbox
          formControlName="cpmOnlyPops"
          class="custom-checkbox mt-12"
        >
          Enabled min CPM only for pops
        </adxad-checkbox>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-select
          placeholder="Select force response type"
          label="Force response type"
          formControlName="responseType"
          [options]="dicts.responseType.data"
        ></adxad-select>
        <app-show-errors [control]="form.get('responseType')"></app-show-errors>

        <adxad-checkbox
          formControlName="responseOnlyPops"
          class="custom-checkbox mt-12"
        >
          Force response only pops
        </adxad-checkbox>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-toggle-button-group
          formControlName="admFormat"
          label="Adm format"
        >
          <adxad-toggle-button
            *ngFor="let format of dicts.admFormat.data"
            [value]="format.id"
          >
            {{ format.value }}
          </adxad-toggle-button>
        </adxad-toggle-button-group>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-select
          placeholder="Select cost model"
          label="Cost model"
          formControlName="costModel"
          [options]="dicts.costModel.data"
        ></adxad-select>
      </adxad-form-field>

      <adxad-form-field class="field-group col-4">
        <adxad-toggle-button-group
          formControlName="noticeType"
          label="Type notice about win"
        >
          <adxad-toggle-button
            *ngFor="let notice of dicts.noticeType.data"
            [value]="notice.id"
          >
            {{ notice.value }}
          </adxad-toggle-button>
        </adxad-toggle-button-group>
      </adxad-form-field>
    </div>

    <div class="adxad-section-title">Advanced filters</div>

    <adxad-form-field class="field-group">
      <adxad-checkbox
        formControlName="mediaTypeFilter"
        class="custom-checkbox"
      >
        Enabled media type filter
      </adxad-checkbox>

      <adxad-holders
        class="media-types"
        *ngIf="form.get('mediaTypeFilter').value"
        [holders]="dicts.mediaTypes.data"
        [isToggleAll]="true"
        formControlName="mediaTypes"
        label="Media types"
      ></adxad-holders>

      <app-show-errors [control]="mediaTypes"></app-show-errors>
    </adxad-form-field>

    <adxad-form-field class="field-group">
      <adxad-checkbox
        class="custom-checkbox"
        formControlName="isNoOpenRTB"
      >
        Enable JSON/XML integration
      </adxad-checkbox>
    </adxad-form-field>

    <ng-container *ngIf="form.get('isNoOpenRTB').value">
      <adxad-form-field class="field-group">
        <adxad-input
          formControlName="accessToken"
          label="Secret"
          placeholder="Secret"
          [suffix]="true"
        >
          <span
            (click)="generateSecret()"
            adxadTooltip="Regenerate secret"
            class="material-icons adxad-suffix --regenerate-access-token"
          >
            refresh
          </span>
        </adxad-input>
      </adxad-form-field>

      <adxad-form-field class="field-group">
        <div class="row">
          <div class="col">
            <adxad-textarea
              label="Template for response"
              placeholder="Enter template"
              formControlName="responseTemplate"
              [fixWidth]="true"
              (blurEvent)="setCursorPosition($event)"
            ></adxad-textarea>
          </div>

          <div class="col">
            <adxad-holders
              [holders]="dicts.macros.data"
              [text]="form.get('responseTemplate').value"
              label="Macros"
              (changeHolder)="toggleMacros($event)"
            ></adxad-holders>
          </div>
        </div>
      </adxad-form-field>
    </ng-container>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        CANCEL
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
      >
        {{ (isNewSSP ? 'Create' : 'Save') | uppercase }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
