import { Component, DestroyRef, inject } from '@angular/core';
import { TokenToolService } from '../token-tool.service';
import { TokenToolResponse } from '../../../../../core/interface';
import { AdxadAlerts } from '../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-token-tool',
  templateUrl: './token-tool.component.html',
  styleUrls: ['./token-tool.component.scss']
})
export class TokenToolComponent {
  height = '360px';
  isLoadingSubmit = false;
  value = null;
  isLoading: boolean = false;

  public encryptedValue$ = new BehaviorSubject<any>('');
  private encryptedValueForCopy$ = new BehaviorSubject<any>('');
  private destroyRef = inject(DestroyRef);

  constructor(
    private tokenToolService: TokenToolService,
    private alerts: AdxadAlerts,
    private clipboard: Clipboard
  ) {}

  /**
   * @return {boolean} flag of disable buttons
   */
  get disabledBtn(): boolean {
    return !this.value?.length;
  }

  submit(type: 'decode' | 'encode') {
    this.isLoading = true;
    this.isLoadingSubmit = true;
    this.encryptedValueForCopy$.next('');

    const requestValue = this.value.trim();

    let request = {
      tokens: [this.checkValueType(requestValue) ? JSON.parse(requestValue) : requestValue]
    };

    this.tokenToolService
      .getData(request, type)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: TokenToolResponse) => {
          this.isLoading = false;
          this.isLoadingSubmit = false;

          if (result.status === 'OK') {
            if (type === 'decode') {
              this.encryptedValue$.next(JSON.stringify(this.decode(result), null, 2));
            } else {
              this.encryptedValue$.next(result.data.tokens[0]['encoded'].trim());
              this.encryptedValueForCopy$.next(result.data.tokens[0]['encoded'].trim());
            }
          }
        },
        error: () => {
          this.isLoading = false;
          this.isLoadingSubmit = false;
        }
      });
  }

  /**
   * Check value type
   * @param value
   */
  checkValueType(value): boolean {
    try {
      JSON.parse(value);
    } catch (err) {
      return false;
    }
    return true;
  }

  /**
   * Clear textarea
   */
  clear() {
    this.value = null;
    this.encryptedValue$.next('');
  }

  /**
   * Copy value in buffer
   */
  copy(): void {
    this.clipboard.copy(this.encryptedValueForCopy$.getValue());
    this.alerts.success('Copied success', 1500);
  }

  /**
   * Convert decode data
   * @param result
   * @private
   */
  private decode(result: TokenToolResponse) {
    const allowed = ['body', 'expired', 'alive'];

    const linked = {
      prid: '/desk/projects?sm_projectView=:id',
      cid: '/desk/campaigns?sm_campaignView=:id',
      crid: '/desk/creatives?sm_creativeView=:id'
    };

    const obj = Object.fromEntries(allowed.map(k => [k, result.data.tokens[0][k]]));

    this.encryptedValueForCopy$.next(JSON.stringify(obj, null, 2));

    const obj2 = Object.fromEntries(
      Object.keys(linked).map(k => {
        const link = linked[k].replace(':id', obj['body'][k]);
        return [k, `<a href=${link} target=_blank>${obj['body'][k]}</a>`];
      })
    );

    return {
      ...obj,
      body: { ...obj.body, ...obj2 }
    };
  }
}
