import { NgModule } from '@angular/core';
import { SspsGridComponent } from './components/ssps-grid/ssp-grid.component';
import { SspViewComponent } from './components/ssp-view/ssp-view.component';
import { SspFormComponent } from './components/ssp-form/ssp-form.component';
import { GuiModule } from '../../../../gui/gui.module';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { SetCommissionFormComponent } from './components/set-commission-form/set-commission-form.component';
import { SspApiKeyComponent } from './components/ssp-api-key/ssp-api-key.component';

const ENTRY_COMPONENTS = [SspsGridComponent, SspViewComponent, SspFormComponent, SetCommissionFormComponent, SspApiKeyComponent];

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS]
})
export class SspsModule {}
