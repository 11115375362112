import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../../../../../core/services/message.service';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { SspFormComponent } from '../ssp-form/ssp-form.component';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import {
  DynamicGridAction,
  DynamicGridColumn,
  FilterChanges,
  FiltersConfig,
  GridRequest,
  SSP,
  SSPsData
} from '../../../../../../core/interface';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadSidebarModal } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { SspViewComponent } from '../ssp-view/ssp-view.component';
import { SspsService } from '../../ssps.service';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Status } from 'src/app/core/configs';
import { SidebarFilterComponent } from '../../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { TranslocoService } from '@jsverse/transloco';
import { DspService } from '../../../../../../core/services/dsp.service';
import { SetCommissionFormComponent } from '../set-commission-form/set-commission-form.component';
import { FiltersService } from '../../../../../../core/services/filters.service';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-ssps-grid',
  templateUrl: './ssp-grid.component.html',
  styleUrls: ['./ssp-grid.component.scss']
})
export class SspsGridComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isLoadingFilters = false;
  public isNotFound = false;
  public isNotCreated = false;
  public readonly Status = Status;
  public columns: DynamicGridColumn[] = [];
  public isLoadingChangeStatus = false;

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<SSPsData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.SSPsService.getSSPs(data))
  );

  constructor(
    private messageService: MessageService,
    private modal: AdxadModal,
    private alerts: AdxadAlerts,
    private sidebarModal: AdxadSidebarModal,
    private dspService: DspService,
    private SSPsService: SspsService,
    private translate: TranslocoService,
    public filter: FiltersService,
    public globica: GlobicaService
  ) {}

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return this.isLoadingChangeStatus || !(this.dynamicGrid && this.dynamicGrid.getChecked().length);
  }

  ngOnInit(): void {
    this.paginator.init();
    this.subscribeFilter();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: SSPsData) => {
        this.isLoading = false;
        this.columns = result.meta?.columns;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.messageService
      .get('reload-ssps-grid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data.submit) {
          this.loadGrid();
        }
      });

    this.loadFilters();
  }

  /**
   * Load & init filters list;
   * Call loadGrid or show error message
   */
  loadFilters(): void {
    this.isLoadingFilters = true;

    this.dspService
      .getFilters('ssps')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: FiltersConfig) => {
          this.isLoadingFilters = false;

          if (status === 'OK') {
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        error: () => (this.isLoadingFilters = false)
      });
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid?.sort?.active,
      direction: this.dynamicGrid?.sort?.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set SSPs grid
   * @param {CampaignData} result
   */
  private setGrid(result: SSPsData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * openView - load & open ssp detail in sidebar modal
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }

    const ssp = action.value as SSP;

    if (action.action === 'toggleStatus') {
      const status = ssp.status === this.Status.active ? this.Status.stopped : this.Status.active;
      this.changeStatus([ssp], status);
    }

    if (action.action === 'openView') {
      if (!action.value.id) {
        return;
      }
      this.openSidebarModal(action.value.id);
    }
  }

  /**
   * Set new status to ssp
   *
   * @param ssp
   * @param status
   */
  changeStatus(ssp: SSP[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    const data = {
      ids: ssp.map(x => x.id),
      status: status
    };

    ssp.forEach(x => (x.isLoading = true));
    this.SSPsService.changeSspStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data || !result.data.length) {
              this.alerts.error('Oops, something wrong', 3000);
              return;
            }

            ssp.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                this.alerts.error('SSP ' + x.name + ' has not changed status', 3000);
                return true;
              }

              x.status = obj.status;
              const message = 'The SSP ' + x.name + (x.status === this.Status.stopped ? ' stopped' : ' activated');
              this.alerts.success(message, 3000);
            });

            if (status === Status.archived) {
              this.messageService.add('reload-ssps-grid', { submit: true });
            }
          }
        },
        error: () => {
          this.isLoadingChangeStatus = false;
          ssp.forEach(x => (x.isLoading = false));
        }
      });
  }

  /**
   * Open sidebar modal for view payment details
   *
   * @param id
   */
  openSidebarModal(id: string): void {
    if (!id) {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    this.sidebarModal.open(SspViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open create ssp form
   */
  openForm(): void {
    this.modal
      .open(SspFormComponent, {
        width: '1120px'
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.loadGrid();
        }
      });
  }

  /**
   * Set commission of selected ssps
   */
  setMassCommission() {
    const ssps = this.dynamicGrid.getChecked();
    this.modal
      .open(SetCommissionFormComponent, {
        width: '560px',
        data: ssps.map(x => x.id)
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadGrid();
          }
        }
      });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.globica.trackEventGoals('buttonFiltersApply_click', { filters: this.filter.globicaFilterRequest });
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  ngOnDestroy() {
    this.messageService.remove('reload-ssps-grid');
  }
}
