import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { EventsLogGridComponent } from './events-log-grid/events-log-grid.component';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

const ENTRY_COMPONENTS = [EventsLogGridComponent];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS, EventsLogGridComponent]
})
export class EventsLogModule {}
