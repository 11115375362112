import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { TranslationsService } from '../../translations.service';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { finalize } from 'rxjs/operators';
import { TranslationKeyFormComponent } from '../translation-key-form/translation-key-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-translations-grid',
  templateUrl: './translations-grid.component.html',
  styleUrls: ['./translations-grid.component.scss']
})
export class TranslationsGridComponent implements OnInit {
  public selectedLocales = [];
  public isFromPreparing = false;
  public isLoadingLocales = [];
  public availableLanguages = [];
  public isLoadingItems = '';
  public form = new UntypedFormGroup({});
  public translateKeyFilter = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    public translate: TranslocoService,
    private fb: UntypedFormBuilder,
    private modal: AdxadModal,
    private alerts: AdxadAlerts,
    private translationsService: TranslationsService,
    private queryService: QueryParamsService
  ) {}

  ngOnInit() {
    this.availableLanguages = this.translate.getAvailableLangs();
    this.changeSelectedLang('en');

    let selectedLocales = this.queryService.get('locales')?.locales;

    if (selectedLocales) {
      selectedLocales = selectedLocales.split(',');

      selectedLocales.forEach(lang => {
        if (lang === 'en') {
          return;
        }

        this.changeSelectedLang(lang);
      });
    }
  }

  /**
   * Return true if control value is empty
   * @param control
   */
  isEmpty(control): boolean {
    return !control.value;
  }

  /**
   * Return form group
   * @param lang
   */
  getLanguageGroup(lang: string): UntypedFormGroup {
    return this.form.get(lang) as UntypedFormGroup;
  }

  /**
   * Return group controls
   * @param group
   */
  getLanguageGroupControls(group: UntypedFormGroup): string[] {
    return Object.keys(group.controls);
  }

  /**
   * Return control
   */
  getLanguageControl(group: UntypedFormGroup, controlName: string): UntypedFormControl {
    return group.controls[controlName] as FormControl;
  }

  /**
   * Change languages
   * @param lang
   */
  changeSelectedLang(lang) {
    let langIndex = this.selectedLocales.findIndex(x => x === lang);

    if (langIndex >= 0) {
      this.selectedLocales.splice(langIndex, 1);
      this.createForm();
    } else {
      this.selectedLocales.push(lang);
      this.isLoadingLocales.push(lang);

      this.translate
        .load(lang)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          finalize(() =>
            this.isLoadingLocales.splice(
              this.isLoadingLocales.findIndex(l => l === lang),
              1
            )
          )
        )
        .subscribe(() => this.createForm());
    }

    this.queryService.add('locales', this.selectedLocales.join(','));
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.isFromPreparing = true;

    this.form = this.fb.group({});

    let enTranslations = this.translate.getTranslation('en');
    const translationKeys = Object.keys(enTranslations);

    this.selectedLocales.forEach(lang => {
      const langGroup = this.fb.group({});
      const translateMap = this.translate.getTranslation(lang);

      translationKeys.forEach(translateId => {
        langGroup.addControl(translateId, this.fb.control(translateMap[translateId] || '', Validators.required));
      });

      this.form.addControl(lang, langGroup);
    });

    this.isFromPreparing = false;
  }

  /**
   * Return language name from key
   * @param key
   */
  getLanguageName(key: string): string {
    const map = {
      en: 'English',
      ja: 'Japanese',
      'zh-cn': 'Simplified chinese',
      'zh-tw': 'Traditional chinese',
      es: 'Spanish',
      uk: 'Ukrainian'
    };

    return map[key] ?? key;
  }

  /**
   * Divide translate groups
   * @param index
   * @param langGroup
   */
  setGroupTitle(index: any, langGroup: UntypedFormGroup): boolean {
    const controls = this.getLanguageGroupControls(langGroup);
    const control = controls[index];
    const prevControl = controls[index - 1];

    if (!prevControl) {
      return true;
    }

    return control.split('_').length > 1 && control.split('_')[0] !== prevControl.split('_')[0];
  }

  /**
   * Return group name
   * @param key
   */
  getGroupTitle(key: string): string {
    const map = {
      menu: 'General navigation',
      timePeriod: 'Time periods',
      tooltips: 'Tooltips',
      action: 'Actions',
      paginator: 'Pagination',
      placeholder: "Placeholder's",
      label: "Label's",
      modal: "Modal's",
      alert: "Alert's",
      status: 'Statuses',
      hint: "Hint's",
      notification: 'Notifications',
      pageTitle: 'Page titles',
      error: "Error's",
      wizard: 'Wizard',
      filter: 'Filters',
      group: 'Reports group',
      step: 'Campaign steps',
      grid: "Grid's",
      paymentStatus: 'Payment statuses'
    };

    return map[key] ?? key;
  }

  addLocale(locale: string, langGroup: UntypedFormGroup, controlName, value: string): void {
    this.getLanguageControl(langGroup, controlName).setValue(`${value}_(${locale})`);
  }

  /**
   * Submit data from control
   * @param locale
   * @param controlName
   * @param value
   * @param index
   * @param langGroup
   */
  save(locale: string, controlName: string, value: string, index: any, langGroup: UntypedFormGroup) {
    const data = { locale, code: controlName, value };
    this.isLoadingItems = 'loading-' + locale + index;

    this.translationsService
      .updateTranslate(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (response.status === 'OK') {
            this.isLoadingItems = '';
            this.alerts.success('Translation successfully changed', 3000);
            langGroup.get(controlName).markAsPristine();
          }
        },
        error: () => (this.isLoadingItems = '')
      });
  }

  /**
   * Open add translation key form
   */
  openForm(): void {
    this.modal
      .open(TranslationKeyFormComponent, {
        width: '588px'
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          window.location.reload();
        }
      });
  }

  /**
   * Copy value in buffer
   *
   * @param value - element value
   */
  copyValue(value): void {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.alerts.success(this.translate.translate('alert_copiedSuccess'), 1500);
  }
}
