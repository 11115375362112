import { Injectable } from '@angular/core';
import { BulkToggleSitesResponse, Config, MultiChangeStatus, SpotData } from '../../../../core/interface';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Request } from '../../../../core/services/dsp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlackListSpotsService {
  private config: Config = environment;

  constructor(private http: HttpClient) {}

  /**
   * Get black list spots grid
   *
   * @param request
   */
  getBlackListSpotsGrid(request: Request): Observable<SpotData> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<SpotData>(`${this.config.dsp_endpoint}/spot/grid`, request, { headers });
  }

  /**
   * Change spot status
   *
   * @param data
   */
  changeSpotsStatus(data: MultiChangeStatus): Observable<BulkToggleSitesResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<BulkToggleSitesResponse>(`${this.config.dsp_endpoint}/spot/store`, data, { headers });
  }
}
