<adxad-loader
  adxadSidebarModalLoader
  [diameter]="40"
  *ngIf="isLoading || isLoadingStatus"
></adxad-loader>

<adxad-sidebar-modal-container *ngIf="segment && !isLoading">
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>

    <h1 adxadSidebarModalTitle>
      <span
        class="material-icons pointer"
        *ngIf="breadcrumbs.list.length"
        (click)="goBack()"
      >
        arrow_back_ios
      </span>
      {{ segment.name }}
    </h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Status"
      >
        <adxad-entity-status [entityStatus]="segment.status"></adxad-entity-status>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Updated at"
      >
        {{ segment.updatedAt | date: 'dd/MM/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      adxadSidebarModalSection
      [hideSeparator]="true"
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Traffic type"
        *ngIf="segment.trafficType.length"
      >
        {{ segment.trafficType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6 --line-break"
        label="Available for roles"
        *ngIf="segment.roles.length"
      >
        {{ lineBreak(segment.roles) }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6 --line-break"
        label="Available for scope"
        *ngIf="segment.scope.length"
      >
        {{ lineBreak(segment.scope) | titlecase }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Composite segment"
        *ngIf="segment.isComposite"
      >
        Enable
      </adxad-sidebar-modal-info-block>
    </section>

    <ng-container *ngIf="segment.includeSegments.length > 0">
      <adxad-sidebar-modal-bar> {{ segment.includeSegments.length }} composite segments</adxad-sidebar-modal-bar>
    </ng-container>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <ng-container *ngIf="segment.status === Status.stopped || segment.status === Status.active">
      <button
        adxadSidebarModalAction
        actionType="main-gray"
        (click)="openEditForm()"
      >
        <span class="material-icons">edit</span>
        Edit
      </button>

      <button
        *ngIf="segment.status === Status.stopped"
        adxadSidebarModalAction
        actionType="main-green"
        (click)="changeStatus(Status.active)"
      >
        <span class="material-icons">play_arrow</span>
        Activate
      </button>

      <button
        *ngIf="segment.status === Status.active"
        adxadSidebarModalAction
        actionType="main-red"
        (click)="changeStatus(Status.stopped)"
      >
        <span class="material-icons">stop</span>
        Stop
      </button>
    </ng-container>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
