<div class="header">
  <section class="filter-toolbar">
    <adxad-calendar
      (changeDates)="filter.changeCalendarDates($event)"
      [from]="filter.calendar?.value?.calendar.from"
      [to]="filter.calendar?.value?.calendar.to"
    ></adxad-calendar>

    <adxad-filter-actions
      (applyFilter)="applyFilter()"
      (clearFilters)="clearFilters()"
      (openFilter)="openFilter()"
      [count]="filter.count"
      [notApplied]="filter.isNotApplied"
    ></adxad-filter-actions>
  </section>
</div>
<adxad-dynamic-grid
  (reload)="loadGrid()"
  [loading]="isLoading"
  [ngClass]="{ 'hide': isNotFound || isNotCreated }"
/>
<adxad-grid-not-found
  (clearFilters)="clearFilters()"
  *ngIf="isNotFound"
/>

<section
  class="not-created"
  *ngIf="isNotCreated"
>
  <img
    class="not-creates__icon"
    src="/assets/images/icons/not-created-icon.svg"
    alt=""
  />
  <h2 class="not-created__title">No events</h2>
  <p class="not-created__text">There`s no events yet.</p>
</section>

<adxad-paginator (changePage)="loadGrid()" />
