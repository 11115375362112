import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DynamicGridAction, GridRequest, Spot, SpotData } from '../../../../../../core/interface';
import { switchMap, tap } from 'rxjs/operators';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import { BlackListSpotsService } from '../../black-list-spots.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { TranslocoService } from '@jsverse/transloco';
import { Status } from '../../../../../../core/configs';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { BulkToggleSitesComponent } from '../bulk-toggle-sites/bulk-toggle-sites.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-black-list-spots-grid',
  templateUrl: './black-list-spots-grid.component.html',
  styleUrls: ['./black-list-spots-grid.component.scss']
})
export class BlackListSpotsGridComponent implements OnInit {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public readonly Status = Status;
  public isLoading = false;
  public isLoadingChangeStatus = false;
  public searchValue = '';
  public onlyBlocked = false;

  private destroyRef = inject(DestroyRef);

  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<SpotData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.spotsService.getBlackListSpotsGrid(data))
  );

  constructor(
    private spotsService: BlackListSpotsService,
    private alerts: AdxadAlerts,
    private translate: TranslocoService,
    private queryParamsService: QueryParamsService,
    private modal: AdxadModal
  ) {}

  ngOnInit(): void {
    this.paginator.init();

    const queryParams = this.queryParamsService.get();
    this.onlyBlocked = !!queryParams.onlyBlocked;
    this.searchValue = queryParams.search || '';

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: SpotData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.loadGrid();
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      search: this.searchValue
      // extra: {
      //   onlyBlocked: this.onlyBlocked
      // }
    } as GridRequest;

    this.onlyBlocked = false;
    this.dynamicGrid.dataSource.filter = '';

    this.requestGrid$.next(request);
  }

  /**
   * Open bulk select popup
   */
  openBulkSelect(): void {
    this.modal
      .open(BulkToggleSitesComponent, {
        width: '588px'
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ submit }) => {
          if (submit) {
            this.resetGrid();
            this.loadGrid();
          }
        }
      });
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  private resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.paginator.resetPage();
    this.setQueryParams();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilter(): void {
    this.searchValue = '';
    this.onlyBlocked = false;
    this.dynamicGrid.dataSource.filter = '';
    this.setQueryParams();
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Set search & onlyBlocked in query params
   */
  setQueryParams(): void {
    this.queryParamsService.add(['search', 'onlyBlocked'], [this.searchValue, this.onlyBlocked]);
  }

  /**
   * Set black list spots grid
   * @param {SpotData} result
   */
  private setGrid(result: SpotData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    }
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return this.isLoadingChangeStatus || !this.dynamicGrid?.getChecked().length;
  }

  /**
   * Check actions in grid
   * toggleStatus - active/block spot
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }
    const spot = action.value as Spot;

    if (action.action === 'toggleStatus') {
      const status = spot.status === this.Status.active ? this.Status.stopped : this.Status.active;
      this.changeStatus([spot], status);
    }
  }

  /**
   * Change status in selected spots
   *
   * @param {number} status
   */
  multiChangeStatus(status: number): void {
    const spots = this.dynamicGrid.getChecked();
    this.changeStatus(spots, status);
  }

  /**
   * Set new status to spot
   *
   * @param spots
   * @param status
   */
  changeStatus(spots: Spot[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    const data = {
      ids: spots.map(x => x.id),
      status: status
    };

    spots.forEach(x => (x.isLoading = true));
    this.spotsService
      .changeSpotsStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data) {
              this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
              spots.forEach(x => (x.isLoading = false));
              return;
            }

            spots.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                return true;
              }

              if (x.status !== obj.status) {
                let message;
                switch (obj.status) {
                  case Status.active: {
                    message = 'alert_spotIsActivated';
                    break;
                  }
                  case Status.stopped: {
                    message = 'alert_spotIsBlocked';
                    break;
                  }
                }

                this.alerts.success(this.translate.translate(message, { spot: x.id }), 3000);

                x.status = obj.status;
              }
            });

            this.dynamicGrid.resetMultiSelect();

            if (status === Status.archived) {
              this.loadGrid();
            }
          }
        },
        error: () => {
          this.isLoadingChangeStatus = false;
          spots.forEach(x => (x.isLoading = false));
        }
      });
  }

  /**
   * Filter grid, if checked view only block sites on current page
   */
  toggleBlocked() {
    this.dynamicGrid.dataSource.filterPredicate = (data: Element, filter: string) => {
      return data['status'] == filter;
    };

    this.dynamicGrid.dataSource.filter = this.onlyBlocked ? '1' : '';
  }
}
