import { NgModule } from '@angular/core';
import { TranslationsGridComponent } from './components/translations-grid/translations-grid.component';
import { GuiModule } from '../../../../gui/gui.module';
import { TranslationKeyFormComponent } from './components/translation-key-form/translation-key-form.component';

const ENTRY_MODULES = [GuiModule];
const ENTRY_COMPONENTS = [TranslationsGridComponent, TranslationKeyFormComponent];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [...ENTRY_MODULES]
})
export class TranslationsModule {}
