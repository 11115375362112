import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { DynamicGridComponent } from '../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../../ui/modules/paginator/paginator.component';
import { Observable, Subject } from 'rxjs';
import { EventsLogService } from '../events-log.service';
import { DspService } from '../../../../../core/services/dsp.service';
import { AdxadAlerts } from '../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadSidebarModal } from '../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { FiltersService } from '../../../../../core/services/filters.service';
import { switchMap, tap } from 'rxjs/operators';
import { EventsGridData, FilterChanges, FiltersConfig, GridRequest } from '../../../../../core/interface';
import { SidebarFilterComponent } from '../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-events-log-grid',
  templateUrl: './events-log-grid.component.html',
  styleUrls: ['./events-log-grid.component.scss'],
  providers: [FiltersService]
})
export class EventsLogGridComponent implements OnInit {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isNotFound = false;
  public isNotCreated = false;

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<EventsGridData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.eventsService.getEventsLogsGrid(data))
  );

  constructor(
    private eventsService: EventsLogService,
    private dspService: DspService,
    private alerts: AdxadAlerts,
    private sidebarModal: AdxadSidebarModal,
    public filter: FiltersService
  ) {}

  ngOnInit(): void {
    this.subscribeFilter();
    this.paginator.init();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: EventsGridData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.loadFilters();
  }

  /**
   * Load & init filters list;
   * Call loadGrid or show error message
   */
  loadFilters(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.dspService
      .getFilters('event_log ')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: FiltersConfig) => {
          this.isLoading = false;

          if (status === 'OK') {
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set events grid
   * @param {EventsGridData} result
   */
  private setGrid(result: EventsGridData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }
}
