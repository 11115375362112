import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DspService, Request } from '../../../../core/services/dsp.service';
import { Config, EventsGridData } from '../../../../core/interface';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsLogService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  getEventsLogsGrid(request: Request): Observable<EventsGridData> {
    return this.http.get<EventsGridData>(`${this.config.dsp_endpoint}/event_log/grid`, {
      params: this.dspService.prepareRequest(request)
    });
  }
}
