<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2
      adxadModalTitle
      data-test="title"
    >
      {{ 'action_bulkToggleSites' | transloco }}
    </h2>
  </adxad-modal-header>

  <adxad-modal-content>
    <adxad-textarea
      [(ngModel)]="value"
      placeholder="Enter ids"
      [fixWidth]="true"
      data-test="field"
      [autofocus]="true"
    >
    </adxad-textarea>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        (click)="close()"
        adxadStrokedButton
        class="w100"
        data-test="cancel-btn"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <button
        (click)="submit('block')"
        adxadButton
        class="w100"
        color="red"
        data-test="block-btn"
      >
        {{ 'action_block' | transloco }}
      </button>
    </div>

    <div class="col">
      <button
        (click)="submit('activate')"
        adxadButton
        class="w100"
        color="green"
        data-test="activate-btn"
      >
        {{ 'action_activate' | transloco }}
      </button>
    </div>
  </adxad-modal-actions>
</ng-container>
