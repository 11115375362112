import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Config, EntityResponse } from '../../../../core/interface';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  private config: Config = environment;

  constructor(private http: HttpClient) {}

  updateTranslate(data: any): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.translationApi}/set`, data, { headers });
  }

  addTranslateKey(data: any): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.translationApi}/newCodes`, data, { headers });
  }
}
