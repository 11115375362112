import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { Dicts } from '../../../../../../core/interface';
import { DspService } from '../../../../../../core/services/dsp.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { SegmentsService } from '../../segments.service';
import { MessageService } from '../../../../../../core/services/message.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-segment-form',
  templateUrl: './segment-form.component.html',
  styleUrls: ['./segment-form.component.scss']
})
export class SegmentFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public isNewSegment = true;
  public dicts = {} as Dicts;

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private fb: UntypedFormBuilder,
    private dspService: DspService,
    private alerts: AdxadAlerts,
    private segmentsService: SegmentsService,
    private messageService: MessageService,
    private rolesService: RolesService
  ) {}

  ngOnInit(): void {
    if (!this.rolesService.isSuperAdmin()) {
      this.close();
      return;
    }

    if (this.config.data && this.config.data.id) {
      this.isNewSegment = false;
    }

    if (this.isNewSegment) {
      this.loadData();
    } else {
      this.loadSegmentData();
    }

    this.createForm();
  }

  /**
   * Load dicts roles, scopes, tariff types & segments
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    const forkList = [
      this.dspService.getRoles(request),
      this.dspService.getScopes(request),
      this.dspService.getTrafficTypes(request),
      this.dspService.getIncludeSegments(request)
    ];

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([roles, scopes, trafficTypes, includeSegments]) => {
          this.dicts = { roles, scopes, trafficTypes, includeSegments };
          this.isLoading = false;
        },
        error: () => this.close()
      });
  }

  /**
   * Load form data
   */
  loadSegmentData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.segmentsService
      .getSegmentForm({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;

          this.loadData();
          this.form.patchValue(result.data);
          this.form.updateValueAndValidity();
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * @return {UntypedFormControl} include segments form control
   */
  get includeSegments(): UntypedFormControl {
    return this.form.get('includeSegments') as UntypedFormControl;
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      extId: ['', [Validators.required]],
      scope: [[], Validators.required],
      trafficType: ['', [Validators.required]],
      sort: ['100', [Validators.required, Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      roles: [[], [Validators.required]],
      isComposite: false,
      includeSegments: [[]]
    });

    if (!this.isNewSegment) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }

    this.form
      .get('name')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(value => {
        if (value.match(/[\u0250-\ue007]/g)) {
          this.form.get('name').setValue(value.replace(/[\u0250-\ue007]/g, ''));
        }
      });

    this.form
      .get('extId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(value => {
        if (value.match(/[\u0250-\ue007]/g)) {
          this.form.get('extId').setValue(value.replace(/[\u0250-\ue007]/g, ''));
        } else {
          this.form.get('extId').setValue(value.replace(/\s+/g, '_'));
          this.form.get('extId').updateValueAndValidity();
        }
      });

    this.form
      .get('isComposite')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value) {
          this.includeSegments.setValidators(Validators.required);
        }

        if (!value) {
          this.includeSegments.patchValue([]);
          this.includeSegments.clearValidators();
        }

        this.includeSegments.updateValueAndValidity();
      });
  }

  /**
   * Submit ssp form
   */
  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;

    const data = this.form.value;
    const method = this.isNewSegment ? this.segmentsService.addSegment(data) : this.segmentsService.editSegment(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        this.isLoadingSubmit = false;
        if (response.status === 'OK') {
          const message = 'Segment successfully ' + (this.isNewSegment ? 'created' : 'changed');
          this.messageService.add('reload-segments-grid', { submit: true });
          this.alerts.success(message, 3000);
          this.close(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
