import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { SspsService } from '../../ssps.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-set-commission-form',
  templateUrl: './set-commission-form.component.html',
  styleUrls: ['./set-commission-form.component.scss']
})
export class SetCommissionFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private alerts: AdxadAlerts,
    private fb: UntypedFormBuilder,
    private SSPsService: SspsService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      commission: ['0', [Validators.required, Validators.min(0)]]
    });
  }

  /**
   * Submit set commission form
   */
  submit(): void {
    if (!this.form.valid) {
      return;
    }

    this.isLoadingSubmit = true;
    const data = this.form.value;
    data['ids'] = this.config.data;

    this.SSPsService.setSspsCommission(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.isLoadingSubmit = false;

          if (response.status === 'OK') {
            this.alerts.success('Commission successfully changed', 3000);
            this.close(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
