import { Injectable } from '@angular/core';
import { Config, TokenToolResponse } from '../../../../core/interface';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenToolService {
  private config: Config = environment;

  constructor(private http: HttpClient) {}

  getData(data: any, type: 'decode' | 'encode'): Observable<TokenToolResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<TokenToolResponse>(`${this.config.dsp_endpoint}/token/${type}`, data, { headers });
  }
}
