import { Injectable } from '@angular/core';
import {
  Config,
  EntityResponse,
  MultiChangeStatus,
  MultiChangeStatusResponse,
  SegmentDetailResponse,
  SegmentObject,
  SegmentsData
} from '../../../../core/interface';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DspService, Request } from '../../../../core/services/dsp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  /**
   * @return {SegmentsData} Get segments grid
   * @param {Request} request
   */
  getSegments(request: Request): Observable<SegmentsData> {
    return this.http.get<SegmentsData>(`${this.config.dsp_endpoint}/segment/grid`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Multi change status in segments
   * @param data
   */
  changeSegmentsStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.dsp_endpoint}/segment/status`, data, { headers });
  }

  getSegment(request: Request): Observable<SegmentDetailResponse> {
    return this.http.get<SegmentDetailResponse>(`${this.config.dsp_endpoint}/segment/get`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  getSegmentForm(request: Request): Observable<SegmentsData> {
    return this.http.get<SegmentsData>(`${this.config.dsp_endpoint}/segment/getForm`, { params: this.dspService.prepareRequest(request) });
  }

  addSegment(data: SegmentObject): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/segment/create`, data, { headers });
  }

  editSegment(data: SegmentObject): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.dsp_endpoint}/segment/update`, data, { headers });
  }
}
