import { Injectable } from '@angular/core';
import {
  ApiKeyResponse,
  Config,
  EntityResponse,
  MultiChangeCommission,
  MultiChangeCommissionResponse,
  MultiChangeStatus,
  MultiChangeStatusResponse,
  SSP,
  SSPResponse,
  SSPsData
} from '../../../../core/interface';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DspService, Request } from '../../../../core/services/dsp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SspsService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  getSSPs(request: Request): Observable<SSPsData> {
    return this.http.get<SSPsData>(`${this.config.dsp_endpoint}/ssp/grid`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  getSsp(request: Request): Observable<SSPResponse> {
    return this.http.get<SSPResponse>(`${this.config.dsp_endpoint}/ssp/get`, { params: this.dspService.prepareRequest(request) });
  }

  getSspForm(request: Request): Observable<SSPResponse> {
    return this.http.get<SSPResponse>(`${this.config.dsp_endpoint}/ssp/getForm`, { params: this.dspService.prepareRequest(request) });
  }

  changeSspStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.dsp_endpoint}/ssp/status`, data, { headers });
  }

  addSsp(data: SSP): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/ssp/create`, data, { headers });
  }

  editSsp(data: SSP): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.dsp_endpoint}/ssp/update`, data, { headers });
  }

  setSspsCommission(data: MultiChangeCommission): Observable<MultiChangeCommissionResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeCommissionResponse>(`${this.config.dsp_endpoint}/ssp/commission`, data, { headers });
  }

  getSspApiKey(id: string): Observable<ApiKeyResponse> {
    return this.http.get<ApiKeyResponse>(`${this.config.dsp_endpoint}/ssp/apiKey/generate?id=${id}`);
  }
}
