import { NgModule } from '@angular/core';
import { GuiModule } from '../../gui/gui.module';
import { SspsModule } from './modules/ssps/ssps.module';
import { SegmentsModule } from './modules/segments/segments.module';
import { SettingsLayoutComponent } from './layout/settings-layout.component';
import { EventsLogModule } from './modules/events-log/events-log.module';
import { TranslationsModule } from './modules/translations/translations.module';
import { BlackListSpotsModule } from './modules/black-list-spots/black-list-spots.module';
import { TokenToolModule } from './modules/token-tool/token-tool.module';
import { RouterModule } from '@angular/router';
import { routes } from './settings.routes';

const ENTRY_MODULES = [SspsModule, SegmentsModule, EventsLogModule, BlackListSpotsModule, TranslationsModule, TokenToolModule];

@NgModule({
  imports: [GuiModule, RouterModule.forChild(routes), ...ENTRY_MODULES],
  exports: [...ENTRY_MODULES],
  declarations: [SettingsLayoutComponent]
})
export class SettingsModule {}
